<template>
  <div class="sign-up-frame">
    <h1 class="title">치료사 회원가입</h1>
    <div class="step-container">
      <div class="step-item-active" v-if="step === 0">
        <img src="@/assets/images/IA/icon_terms_active.png" alt="terms">
        <div>
          <p>STEP 1</p>
          <h5>약관동의</h5>
        </div>
      </div>
      <div class="step-item" v-else>
        <img src="@/assets/images/IA/icon_terms.png" alt="terms">
        <div>
          <p>STEP 1</p>
          <h5>약관동의</h5>
        </div>
      </div>
      <div class="step-item-active" v-if="step === 1">
        <img src="@/assets/images/IA/icon_user_active.png" alt="terms">
        <div>
          <p>STEP 2</p>
          <h5>회원정보입력</h5>
        </div>
      </div>
      <div class="step-item" v-else>
        <img src="@/assets/images/IA/icon_user.png" alt="terms">
        <div>
          <p>STEP 2</p>
          <h5>회원정보입력</h5>
        </div>
      </div>
      <div class="step-item-active" v-if="step === 2">
        <img src="@/assets/images/IA/icon_hospi_active.png" alt="terms">
        <div>
          <p>STEP 3</p>
          <h5>병원정보입력</h5>
        </div>
      </div>
      <div class="step-item" v-else>
        <img src="@/assets/images/IA/icon_hospi.png" alt="terms">
        <div>
          <p>STEP 3</p>
          <h5>병원정보입력</h5>
        </div>
      </div>
      <div class="step-item-active" v-if="step === 3">
        <img src="@/assets/images/IA/icon_success_active.png" alt="terms">
        <div>
          <p>STEP 4</p>
          <h5>가입완료</h5>
        </div>
      </div>
      <div class="step-item" v-else>
        <img src="@/assets/images/IA/icon_success.png" alt="terms">
        <div>
          <p>STEP 4</p>
          <h5>가입완료</h5>
        </div>
      </div>
    </div>
    <TermsView v-show="step === 0" @setStep="setStep"/>
    <UserInfoView v-show="step === 1" @setStep="setStep"/>
    <HospitalInfoView v-show="step === 2" @setStep="setStep"/>
    <CompleteView v-show="step === 3"/>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import TermsView from "@/views/common/auth/therapist/tab/TermsView";
import UserInfoView from "@/views/common/auth/therapist/tab/UserInfoView";
import HospitalInfoView from "@/views/common/auth/therapist/tab/HospitalInfoView";
import CompleteView from "@/views/common/auth/therapist/tab/CompleteView";
import Progress from "@/components/Progress";
import axios from "axios";

export default {
  name: "TherapistSignUp",
  components: {
    Progress,
    CompleteView,
    HospitalInfoView,
    UserInfoView,
    TermsView,
  },
  data() {
    return {
      fbCollection: 'therapists',
      step: 0,
      userInfo: {},
      hospitalInfo: {},
      token: '',
      isProgress: false,
    }
  },
  methods: {
    setStep: function (step, info, token) {
      const self = this;
      if (step === 2) {
        self.userInfo = info
        self.token = token
        self.step = step;
      } else if (step === 3) {
        self.hospitalInfo = info
        self.isProgress = true;
        self.signUp(step)
      } else {
        self.step = step;
      }
    },
    async signUp(step) {
      const self = this;

      const _userInfo = self.userInfo
      _userInfo.password = self.token;
      _userInfo.hospitalInfo = JSON.stringify(self.hospitalInfo)

      const config = {
        method: 'post',
        url: `${self.$store.state.serverUrl}/createTherapist`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: _userInfo
      };

      await axios(config)
          .then(res => {
            self.isProgress = false;
            if (res.data.result === 'success') {
              self.step = step;
            } else {
              console.log('오류발생')
            }
          })
    },
  }
}
</script>

<style scoped>

h5 {
  font-size: 18px !important;
}

.sign-up-frame {
  padding: 40px 0 60px 0;
  margin: 0 auto;
  width: 805px;
}

.sign-up-frame .title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0;
}

.sign-up-frame .step-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sign-up-frame .step-item {
  flex: 1;
  height: 86px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step-item img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-right: 10px;
}

.step-item div p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #B1B1B1;
  margin: 0;
}

.step-item div h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #B1B1B1;
  margin: 0;
}


.sign-up-frame .step-item-active {
  flex: 1;
  height: 86px;
  background: #0069CA;
  border: 1px solid #0069CA;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step-item-active img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-right: 10px;
}

.step-item-active div p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #FFFFFF;
  margin: 0;
}

.step-item-active div h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  margin: 0;
}

.terms-check-container {
  width: 100%;
  height: 60px;
  left: 153px;
  top: 255px;
  background: #F8F8FA;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

/deep/ .form-check {
  padding: 0;
  margin: 0;
}

/deep/ .form-check-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;

  color: #000000;
  margin: 0;
}

.terms-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #8D8D8D;
}

.terms-box {
  margin-top: 20px;
  margin-bottom: 30px;
}

.first-line {
  display: flex;
  align-items: center;
}

.first-line h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
}

.first-line h4 strong {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #0069CA;
}

.sub-term {
  margin-left: auto;
}

.sub-term /deep/ .mr-5 {
  margin: 0 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  text-decoration-line: none;
  padding-top: 2px;
}

.terms-text {
  padding: 15px 20px;
  width: 805px;
  height: 233px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  margin-top: 10px;
  overflow: auto;
}

.terms-text p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #8D8D8D;
}

.btn-line {
  text-align: right;
}

.next-btn {
  width: 128px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.prev-btn {
  width: 128px;
  height: 40px;
  margin-right: 10px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}


/deep/ .form-control {
  margin: 0;
}
</style>
