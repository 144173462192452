<template>
  <div class="hospital-frame">
    <p class="guide">‘ <strong>*</strong> ’ 항목은 입력 필수 항목입니다.</p>
    <div class="line"></div>
    <div class="info-container">
      <h5>소속병원 이름</h5>
      <input type="text" v-model="hospitalName" placeholder="병원 이름 입력" class="custom-input-box w444"/>
    </div>
    <div class="info-container">
      <h5>소속병원 형태<strong>*</strong></h5>
      <mdb-select
          :scroll=false
          outline
          v-model="hospitalTypeOptions"
          placeholder="선택"
          @getValue="getHospitalTypeValue"
          class="search-select "/>
    </div>
    <div class="info-container">
      <h5>소속병원 위치<strong>*</strong></h5>
      <mdb-select
          :scroll=false
          outline
          v-model="hospitalLocationOptions"
          placeholder="선택"
          @getValue="getHospitalLocationValue"
          class="search-select "/>
    </div>
    <div class="info-container">
      <h5>직업</h5>
      <mdb-select
          :scroll=false
          outline
          v-model="jobOptions"
          placeholder="선택"
          @getValue="getJobValue"
          class="search-select "/>
    </div>
    <div class="info-container">
      <h5>근무년수</h5>
      <mdb-select
          :scroll=false
          outline
          v-model="workingYearOptions"
          placeholder="선택"
          @getValue="getWorkingYearValue"
          class="search-select "/>
    </div>
    <div class="line-thin"></div>
    <div class="btn-line">
      <button class="prev-btn" @click="prevStep">이전</button>
      <button class="next-btn" @click="nextStep">다음</button>
    </div>
  </div>
</template>

<script>
import {mdbSelect} from "mdbvue";

export default {
  name: "HospitalInfoView",
  components: {
    mdbSelect,
  },
  data() {
    return {
      hospitalName: '',
      hospitalType: '',
      hospitalLocation: '',
      job: '',
      workingYear: '',
      hospitalTypeOptions: [
        {text: "1차 병원(의원급)", value: "1차 병원(의원급)"},
        {text: "2차 병원(재활전문병원 및 요양병원급)", value: "2차 병원(재활전문병원 및 요양병원급)"},
        {text: "3차 병원(종합병원급)", value: "3차 병원(종합병원급)"},
      ],

      hospitalLocationOptions: [
        {text: "서울 및 경기도", value: "서울 및 경기도"},
        {text: "광역시", value: "광역시"},
        {text: "강원도", value: "강원도"},
        {text: "경상도", value: "경상도"},
        {text: "전라도", value: "전라도"},
        {text: "충청도", value: "충청도"},
        {text: "제주도", value: "제주도"},
      ],

      jobOptions: [
        {text: "물리치료사", value: "물리치료사"},
        {text: "작업치료사", value: "작업치료사"},
      ],

      workingYearOptions: [
        {text: "1년 미만", value: "1년 미만"},
        {text: "1~3년", value: "1~3년"},
        {text: "3~5년", value: "3~5년"},
        {text: "5~10년", value: "5~10년"},
        {text: "10년 이상", value: "10년 이상"},
      ],
      hospitalInfo: {
        location: '',
        name: '',
        type: '',
        job: '',
        workingYear: ''
      }
    }
  },
  methods: {
    getHospitalTypeValue(value) {
      const self = this;
      self.hospitalType = value
    },
    getHospitalLocationValue(value) {
      const self = this;
      self.hospitalLocation = value
    },
    getJobValue(value) {
      const self = this;
      self.job = value
    },
    getWorkingYearValue(value) {
      const self = this;
      self.workingYear = value
    },
    prevStep() {
      const self = this;
      self.$emit('setStep', 1);
    },
    nextStep() {
      const self = this;
      if (self.checkValidate()) {
        self.$emit('setStep', 3, self.hospitalInfo);
      }
    },
    checkValidate() {
      const self = this;
      if (self.hospitalType.trim().length === 0) {
        alert('소속병원 형태를 입력하세요.')
        return false
      } else if (self.hospitalLocation.trim().length === 0) {
        alert('소속병원 위치를 입력하세요.')
        return false
      }

      self.hospitalInfo = {
        location: this.hospitalLocation,
        name: this.hospitalName,
        type: this.hospitalType,
        job: this.job,
        workingYear: this.workingYear
      }

      return true
    },
  }
}
</script>

<style scoped>
.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 60px 0 0;
}

strong {
  color: #E14F4E;
}

.line {
  width: 805px;
  height: 2px;
  background-color: #000000;
  margin: 40px 0 30px;
}

.line-thin {
  width: 805px;
  height: 1px;
  background-color: #000000;
  margin: 35px 0 30px;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.info-container input {
  font-size: 16px;
}

.info-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #272833;
  width: 150px;
  margin-right: 10px;
}

.w444 {
  width: 444px;
}

/deep/ .search-select .md-form {
  width: 444px;
  margin: 0;
  background: url('../../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
  height: 40px;

}

/deep/ .diagnosis-search .form-control::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}

.btn-line {
  text-align: right;
}

.next-btn {
  width: 128px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.prev-btn {
  width: 128px;
  height: 40px;
  margin-right: 10px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}
</style>
