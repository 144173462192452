<template>
  <div class="terms-check-frame">
    <div class="terms-check-container">
      <mdb-input type="checkbox" id="termsCheckAll"
                 @change="handleCbxAll"
                 v-model="termsCheckAll" label="이용약관, 개인정보처리방침에 모두 동의합니다."/>
    </div>

    <div class="terms-box">
      <div class="first-line">
        <h4>이용약관<strong>(필수)</strong></h4>
        <div class="sub-term">
          <mdb-input type="checkbox" id="termsCheck_1"
                     @change="termsCbxService"
                     v-model="termsCheck_1" label="이용약관에 동의"/>
        </div>
      </div>
      <div class="terms-text">
        <p v-html="getContent(service_text)" class="text"></p>
      </div>
    </div>

    <div class="terms-box">
      <div class="first-line">
        <h4>개인정보처리방침<strong>(필수)</strong></h4>
        <div class="sub-term">
          <mdb-input type="checkbox" id="termsCheck_2"
                     @change="termsCbxService"
                     v-model="termsCheck_2" label="이용약관에 동의"/>
        </div>
      </div>
      <div class="terms-text">
        <p v-html="getContent(private_text)" class="text"></p>
      </div>
    </div>
    <div class="btn-line">
      <button class="next-btn" @click="nextStep">다음</button>
    </div>
  </div>
</template>

<script>
import {mdbInput} from "mdbvue";
import terms_text from "@/assets/data/terms_text";

export default {
  name: "TermsView",
  components: {
    mdbInput,
  },
  data() {
    return {
      termsCheckAll: false,
      termsCheck_1: false,
      termsCheck_2: false,
      service_text: terms_text.service,
      private_text: terms_text.private,
      termsSum: 0,
      isAllClick: false,
      lastClickNum: 0,
    }
  },
  methods: {
    getContent(content) {
      return content.split('\n').join('<br>');
    },
    nextStep() {
      const self = this;
      if (self.termsCheckAll) {
        self.$emit('setStep', 1);
      } else {
        alert('모든 약관에 동의해 주세요.');
      }
    },
    handleCbxAll(value) {
      const self = this;
      if (self.isAllClick) {
        self.isAllClick = false;
      } else {
        self.termsCheck_1 = value;
        self.termsCheck_2 = value;
      }
    },
    termsCbxService(value) {
      const self = this;
      self.isAllClick = true;
      if (
          self.termsCheck_1 &&
          self.termsCheck_2
      ) {
        self.termsCheckAll = value
      }
    },
  }
}
</script>

<style scoped>
.sign-up-frame {
  padding: 40px 0 60px 0;
  margin: 0 auto;
  width: 805px;
}

.sign-up-frame .title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0;
}

.sign-up-frame .step-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sign-up-frame .step-item {
  flex: 1;
  height: 86px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step-item img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-right: 10px;
}

.step-item div p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #B1B1B1;
  margin: 0;
}

.step-item div h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #B1B1B1;
  margin: 0;
}


.sign-up-frame .step-item-active {
  flex: 1;
  height: 86px;
  background: #0069CA;
  border: 1px solid #0069CA;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step-item-active img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-right: 10px;
}

.step-item-active div p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #FFFFFF;
  margin: 0;
}

.step-item-active div h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  margin: 0;
}

.terms-check-container {
  width: 100%;
  height: 60px;
  left: 153px;
  top: 255px;
  background: #F8F8FA;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

/deep/ .form-check {
  padding: 0;
  margin: 0;
}

/deep/ .form-check-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;

  color: #000000;
  margin: 0;
}

.terms-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #8D8D8D;
}

.terms-box {
  margin-top: 20px;
  margin-bottom: 30px;
}

.first-line {
  display: flex;
  align-items: center;
}

.first-line h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
}

.first-line h4 strong {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #0069CA;
}

.sub-term {
  margin-left: auto;
}

.sub-term /deep/ .mr-5 {
  margin: 0 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  text-decoration-line: none;
  padding-top: 2px;
}

.terms-text {
  padding: 15px 20px;
  width: 805px;
  height: 233px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  margin-top: 10px;
  overflow: auto;
}

.terms-text p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #8D8D8D;
}

.btn-line {
  text-align: right;
}

.next-btn {
  width: 128px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}
</style>
